// @ts-nocheck
import { Envs, Utils } from '../../common';
import { React, ReactDOM, Styled, StyledF } from '../../common/3rd';
import { ButtonType, StyledProps } from '../../types';
import Styles, { Phone, StyledComponents } from '../../styles';

const Wrapper = Styled.div.attrs({
	'data-widget': 'alert-wrapper'
})`
	position: relative;
	display: ${(props: { shown?: boolean }) => (props.shown ? 'block' : 'none')};
	${(props: any) => {
		if (!props.hint) {
			return '';
		}

		return `
			div[data-widget=alert-box] {
				top: 100vh;
				transition: top 400ms ease-in-out;
			}
			div[data-widget=alert-body] {
				background-color: ${props.theme.ALERT_HINT_BACKGROUND_COLOR};
				> i[data-widget=alert-icon] {
					color: ${props.theme.ALERT_HINT_ICON_COLOR};
				}
			}
			div[data-widget=alert-msg-block] {
				min-height: auto;
			}
			div[data-widget=alert-msg-line] {
				word-break: keep-all;
			}
			${props.hintIn
				? `
				div[data-widget=alert-box] {
					top: 60vh;
				}
			`
				: ''
			}
			${props.hintOut
				? `
				div[data-widget=alert-box] {
					top: -10vh;
				}
			`
				: ''
			}
		`;
	}}

	${(props: any) => {
		if (!props.fadeAway) {
			return '';
		}

		return `
			div[data-widget=alert-box] {
			
			}
			div[data-widget=alert-body] {
				background-color: ${props.theme.ALERT_HINT_BACKGROUND_COLOR};
				> i[data-widget=alert-icon] {
					color: ${props.theme.ALERT_HINT_ICON_COLOR};
				}
			}
			div[data-widget=alert-msg-block] {
				min-height: auto;
			}
			div[data-widget=alert-msg-line] {
				word-break: keep-all;
			}

			${props.hintOut
				? `
				div[data-widget=alert-box] {
					animation-name: fadeOut; /*动画名称*/
					animation-duration: 2s; /*动画持续时间*/
					animation-iteration-count: 1; /*动画次数*/
					animation-delay: 0s; /*延迟时间*/
					-webkit-animation-name: fadeOut; /*动画名称*/
					-webkit-animation-duration: 2s; /*动画持续时间*/
					-webkit-animation-iteration-count: 1; /*动画次数*/
					-webkit-animation-delay: 0s; /*延迟时间*/

					@keyframes fadeOut {
						0% {
							opacity: 1; /*初始状态 透明度为0*/
						}
						10% {
							opacity: 0.9; /*中间状态 透明度为0*/
						}
						20% {
							opacity: 0.8; /*中间状态 透明度为0*/
						}
						30% {
							opacity: 0.7; /*中间状态 透明度为0*/
						}
						40% {
							opacity: 0.6; /*中间状态 透明度为0*/
						}
						50% {
							opacity: 0.5; /*中间状态 透明度为0*/
						}
						60% {
							opacity: 0.4; /*中间状态 透明度为0*/
						}
						70% {
							opacity: 0.3; /*中间状态 透明度为0*/
						}
						80% {
							opacity: 0.2; /*中间状态 透明度为0*/
						}
						90% {
							opacity: 0.1; /*中间状态 透明度为0*/
						}
						100% {
							opacity: 0; /*结尾状态 透明度为1*/
						}
					}
				}
			`
				: ''
			}
		`;
	}}

` as any;

const Backdrop = Styled.div.attrs({
	'data-widget': 'alert-backdrop'
})`
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	background-color: ${props => props.theme.BACKDROP_COLOR};
	z-index: ${props => props.theme.ALERT_Z_INDEX};
`;

const Box = Styled.div.attrs({
	'data-widget': 'alert-box'
})`
	display: block;
	position: fixed;
	top: 25vh;
	left: 35vw;
	max-height: 50vh;
	width: 30vw;
	border-radius: ${props => props.theme.BORDER_RADIUS}px;
	padding: ${props => props.theme.PANEL_PADDING}px;
	background: ${props => props.theme.PANEL_BACKGROUND_COLOR};
	z-index: ${props => props.theme.ALERT_Z_INDEX + 1};
	box-shadow: ${props => props.theme.ALERT_SHADOW};

	${Phone.only`
		padding: 0;
		width: 80vw;
		left: 10vw;
	`}
`;

const Body = Styled.div.attrs({
	'data-widget': 'alert-body'
})`
	display: flex;
	justify-content: flex-start;
	overflow: hidden;
	margin-bottom: ${props => props.theme.MARGIN}px;

	${Phone.only`
		padding: ${(props: StyledProps) => props.theme.PANEL_PADDING}px;
		margin-bottom: 0;
	`}
`;

const Rotate = StyledF.keyframes`
	from {transform: rotate(0deg);}
	to {transform: rotate(360deg);}
`;

const Spinner = Styled.div.attrs({
	'data-widget': 'alert-spinner'
})`
	width: 3px; 
	height: 3px;
	margin: 16px 20px;
	border-radius: 100%;                      /* 圆角 */
	box-shadow: 0 -10px 0 1px grey,           /* 上, 1px 扩展 */
				10px 0px grey,                /* 右 */    
				0 10px grey,                  /* 下 */
				-10px 0 grey,                 /* 左 */
							
				-7px -7px 0 .5px grey,        /* 左上, 0.5px扩展 */
				7px -7px 0 1.5px grey,        /* 右上, 1.5px扩展 */                    
				7px 7px grey,                 /* 右下 */
				-7px 7px grey;                /* 左下 */
	animation: 2s linear infinite;
	animation-name: ${Rotate};
`;

const Icon = (props: any) => (
	<StyledComponents.FAS
		data-widget="alert-icon"
		{...props}
		css={props => {
			return `
				height: ${props.theme.MIDDLE_HEIGHT}px;
				line-height: ${props.theme.MIDDLE_HEIGHT}px;
				width: ${props.theme.ICON_WIDTH}px;
				min-width: ${props.theme.ICON_WIDTH}px;
				font-size: 22px;
			`;
		}}
	/>
);

const MsgBlock = Styled.div.attrs({
	'data-widget': 'alert-msg-block'
})`
	flex-grow: 1;
	min-height: 80px;
	max-height: calc(50vh - ${props => props.theme.PANEL_PADDING * 2 + props.theme.MARGIN + props.theme.BTN_HEIGHT}px);
	overflow-y: auto;
	overflow-x: hidden;

	span:first-child {
		margin-top: ${props => (props.theme.MIDDLE_HEIGHT - props.theme.SHORT_HEIGHT) / 2}px;
	}
`;

const MsgLine = Styled.span.attrs({
	'data-widget': 'alert-msg-line'
})`
	display: block;
	position: relative;
	word-break: break-word;
	min-height: ${props => props.theme.SHORT_HEIGHT}px;
	line-height: ${props => props.theme.SHORT_HEIGHT}px;
`;

const ButtonBar = Styled.div.attrs({
	'data-widget': 'alert-btn-bar'
})`
	display: flex;
	justify-content: flex-end;
	a:not(:last-child) {
		margin-right: ${props => props.theme.MARGIN}px;
	}

	${Phone.only`
		a {
			flex-grow: 1;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			&:not(:last-child) {
				margin-right: 0;
				border-bottom-right-radius: 0;
			}
			&:not(:first-child) {
				border-bottom-left-radius: 0;
			}
		}
	`}
`;

const Button = Styled(StyledComponents.BUTTON).attrs({
	'data-widget': 'alert-btn'
})`
	height: ${props => props.theme.ROW_HEIGHT}px;
	line-height: ${props => props.theme.ROW_HEIGHT - props.theme.BTN_VERTICAL_PADDING * 2}px;
`;

/**
 * 警告对话框, 包括WARN, INFO, SUCCESS, ASK, ASK-REVERSE, LOADING
 */
class Alert extends React.Component {
	static WARN = 'w';
	static SUCCESS = 's';
	static INFO = 'i';
	static ERROR = 'e';
	static ASK = 'q';
	static LOADING = 'l';
	static ASK_REVERSED = 'qr';
	static HINT = 'h';
	static FADE_AWAY = 'fa';//渐渐消息
	static ASK_APPLY = 'qa';//
	static MODAL = 'm';
	state: {
		reverseColors?: string | null;
		type?: string | null;
		messages?: string | string[] | null;
		active?: HTMLElement | null;
		resolve?: (arg?: any) => void | null;
		reject?: (arg?: any) => void | null;
		hintIn?: true;
		hintOut?: true;
		yesOrNo?: boolean;
		cancelText?: string;
		confirmText?: string;
	} = {};
	private renderButtons(): JSX.Element | null {
		if (this.state.type === Alert.HINT) {
			return null;
		}
		if (this.state.type === Alert.FADE_AWAY) {
			return null;
		}

		const waive = this.state.reverseColors ? ButtonType.PRIMARY : ButtonType.WAIVE;
		const primary = this.state.reverseColors ? ButtonType.WAIVE : ButtonType.PRIMARY;
		const buttonConfirmWord = this.state.yesOrNo === true ? '是' : '确认';
		const buttonCancelWord = this.state.yesOrNo === true ? '否' : '取消';

		if (this.state.type === Alert.MODAL) {
			return (
				<ButtonBar>
					<Button onClick={this.onCancelClicked} type={waive}>
						{this.state.cancelText}
					</Button>
					<Button onClick={this.onConfirmClicked} type={primary}>
						{this.state.confirmText}
					</Button>
				</ButtonBar>
			);
		} else if (this.state.type === Alert.ASK) {
			return (
				<ButtonBar>
					<Button onClick={this.onCancelClicked} type={waive}>
						{buttonCancelWord}
					</Button>
					<Button onClick={this.onConfirmClicked} type={primary}>
						{buttonConfirmWord}
					</Button>
				</ButtonBar>
			);
		} else if (this.state.type === Alert.ASK_REVERSED) {
			return (
				<ButtonBar>
					<Button onClick={this.onConfirmClicked} type={primary}>
						{buttonConfirmWord}
					</Button>
					<Button onClick={this.onCancelClicked} type={waive}>
						{buttonCancelWord}
					</Button>
				</ButtonBar>
			);
		} else if (this.state.type === Alert.ASK_APPLY) {
			return (
				<ButtonBar>
					<Button onClick={this.onCancelClicked} type={waive}>
						取消投保
					</Button>
					<Button onClick={this.onConfirmClicked} type={primary}>
						继续投保
					</Button>
				</ButtonBar>
			);
		} else if (this.state.type === Alert.LOADING) {
			return null;
		} else {
			return (
				<ButtonBar>
					<Button onClick={this.onConfirmClicked} type={ButtonType.PRIMARY}>
						{buttonConfirmWord}
					</Button>
				</ButtonBar>
			);
		}
	}
	private renderMessages(): JSX.Element[] {
		let messages = Utils.toArray(this.state.messages);
		return messages.map((msg, index) => {
			return (
				<MsgLine key={index} data-widget="alert-msg-line">
					{msg}
				</MsgLine>
			);
		});
	}
	private renderIcon(): JSX.Element {
		const props: {
			icon?: string;
			color?: string;
			spin?: boolean;
			className?: string;
		} = {};
		switch (this.state.type) {
			case Alert.WARN:
				props.icon = 'exclamation-circle';
				props.color = 'warn';
				break;
			case Alert.SUCCESS:
				props.icon = 'check-circle';
				props.color = 'success';
				break;
			case Alert.INFO:
			case Alert.HINT:
			case Alert.FADE_AWAY:
				props.icon = 'info-circle';
				props.color = 'info';
				break;
			case Alert.ERROR:
				props.icon = 'times-circle';
				props.color = 'error';
				break;
			case Alert.ASK:
			case Alert.ASK_REVERSED:
			case Alert.ASK_APPLY:
				props.icon = 'question-circle';
				props.color = 'question';
				break;
			case Alert.MODAL:
				props.icon = 'question-circle';
				props.color = 'question';
				break;
			case Alert.LOADING:
				props.icon = 'spinner';
				props.color = 'info';
				props.spin = true;
				break;
			default:
				props.icon = 'info-circle';
				props.color = 'info';
				props.className = 'fa-spin';
				break;
		}
		if (props.spin) {
			return <Spinner />;
		} else {
			return <Icon {...props} data-widget="alert-icon" />;
		}
	}
	render(): JSX.Element {
		if (this.state.type) {
			return (
				<StyledF.ThemeProvider theme={Styles.getTheme(Envs.getCurrentTheme())}>
					<Wrapper
						shown={this.isShown()}
						fadeAway={this.state.type === Alert.FADE_AWAY}
						hint={this.state.type === Alert.HINT}
						hintIn={this.state.hintIn}
						hintOut={this.state.hintOut}
					>
						<Backdrop />
						<Box>
							<Body>
								{this.renderIcon()}
								<MsgBlock>{this.renderMessages()}</MsgBlock>
							</Body>
							{this.renderButtons()}
						</Box>
					</Wrapper>
				</StyledF.ThemeProvider>
			);
		} else {
			return <div />;
		}
	}
	warn(messages: string | string[]): Promise<void> {
		return this.show(messages, Alert.WARN);
	}
	error(messages: string | string[]): Promise<void> {
		return this.show(messages, Alert.ERROR);
	}
	info(messages: string | string[]): Promise<void> {
		return this.show(messages, Alert.INFO);
	}
	success(messages: string | string[]): Promise<void> {
		return this.show(messages, Alert.SUCCESS);
	}
	ask(messages: string | string[], reverseButtons = false, reverseButtonColors = false, yesOrNo? = false, params?: any): Promise<void> {
		return this.show(messages, reverseButtons ? Alert.ASK_REVERSED : Alert.ASK, reverseButtonColors, yesOrNo, params);
	}
	askApply(messages: string | string[]): Promise<void> {
		return this.show(messages, Alert.ASK_APPLY);
	}
	loading(): Promise<void> {
		return this.show('努力加载中...', Alert.LOADING);
	}
	modal(messages: string | string[], options = { cancelText: "取消", confirmText: "确认" }): Promise<void> {
		return this.show(messages, Alert.MODAL, false, false, options);
	}
	posting(): Promise<void> {
		return this.show('提交数据中...', Alert.LOADING);
	}
	ing(message: string): Promise<void> {
		return this.show(message, Alert.LOADING);
	}
	hint(message: string | string[]): Promise<void> {
		return this.show(message, Alert.HINT);
	}
	fadeAway(message: string | string[]): Promise<void> {
		return this.show(message, Alert.FADE_AWAY);
	}
	show(messages: string | string[], type: string, reverseButtonColors = false,
		yesOrNo = false, options = { cancelText: "取消", confirmText: "确认" }): Promise<void> {
		if (this.isShown()) {
			// 已经在显示状态
			this.hide();
		}

		return new Promise((resolve, reject) => {
			const { cancelText = "取消", confirmText = "确认" } = options;
			// 弹框, 记录活动的元素
			// 去掉焦点, 实际上是为了在移动设备上去掉软键盘
			const active = document.activeElement as HTMLElement;
			active && active.blur();

			this.setState(
				{
					messages: messages,
					type: type,
					reverseColors: reverseButtonColors,
					resolve: resolve,
					reject: reject,
					active: active,
					yesOrNo: yesOrNo,
					cancelText: cancelText,
					confirmText: confirmText,
				},
				() => {
					Envs.application()!.setScrollDisabled(true);
					if (type === Alert.HINT) {
						// 30毫秒后进入屏幕
						setTimeout(() => {
							this.setState(
								{
									hintIn: true
								},
								() => {
									// 位置停稳后, 再过3秒钟后移出屏幕
									setTimeout(() => {
										this.setState(
											{
												hintOut: true
											},
											() => {
												// 移出屏幕后关闭
												setTimeout(() => {
													this.hide();
												}, 400);
											}
										);
									}, 2400);
								}
							);
						}, 30);
					}

					if (type === Alert.FADE_AWAY) {
						this.setState(
							{
								hintIn: true
							},
							() => {
								// 位置停稳后, 再过3秒钟后移出屏幕
								setTimeout(() => {
									this.setState(
										{
											hintOut: true
										},
										() => {
											// 移出屏幕后关闭
											setTimeout(() => {
												this.hide();
											}, 900);
										}
									);
								}, 1000);
							}
						);
					}
				}
			);
		});
	}

	progress(messages: string | string[]): void {
		this.setState({
			messages: messages
		});
	}
	/**
	 * 隐藏警告对话框, 默认resolve promise
	 */
	hide(resolved = true): void {
		const handlePromise: any = resolved ? this.state.resolve : this.state.reject;
		const active = this.state.active;
		// remove all states
		this.setState(
			{
				messages: undefined,
				type: undefined,
				reverseColors: undefined,
				resolve: undefined,
				reject: undefined,
				active: undefined,
				hintIn: undefined,
				hintOut: undefined
			},
			() => {
				Envs.application()!.setScrollDisabled(false);
				// 恢复焦点
				active && active.focus();
				handlePromise && handlePromise();
			}
		);
	}
	isShown(): boolean {
		return this.state.messages != null;
	}
	private onConfirmClicked = (): void => {
		this.hide(true);
	};
	private onCancelClicked = (): void => {
		this.hide(false);
	};
	// redressPosition() {
	// 	setTimeout(() => {
	// 		const scrollTop = document.body.scrollTop;
	// 		if (Envs.iphone && Envs.wechat && scrollTop !== 0) {
	// 			const focus = document.activeElement;
	// 			if (focus != null && focus.tagName !== 'BODY') {
	// 				const tagName = focus.tagName.toUpperCase();
	// 				const shouldAdjust = (() => {
	// 					if (tagName === 'SELECT' || tagName === 'TEXTAREA') {
	// 						// keyboard is shown
	// 						return true;
	// 					} else if (tagName === 'INPUT') {
	// 						const type = (
	// 							focus.getAttribute('type') || ''
	// 						).toUpperCase();
	// 						if (
	// 							type === 'TEXT' ||
	// 							type === 'PASSWORD' ||
	// 							type === 'DATE'
	// 						) {
	// 							// keyboard is shown
	// 							return true;
	// 						}
	// 					}
	// 					return false;
	// 				})();
	// 				this.setState({
	// 					keyboard: shouldAdjust
	// 				});
	// 			}
	// 		}
	// 	}, 30);
	// }
}

let container = document.getElementById('alert-container-cc');
if (container == null) {
	container = document.createElement('DIV');
	container.id = 'alert-container-cc';
	document.body.appendChild(container);
}

const AlertRef = React.createRef() as React.RefObject<Alert>;
ReactDOM.render(<Alert ref={AlertRef} />, container);
export default AlertRef.current as Alert;
